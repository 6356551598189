<template>
  <footer class="footer">
    <p v-if="company != null">{{companyName}}</p>
  </footer>
</template>

<script>
export default {
  name: "PageFooter",
  props: {
    company: Object
  },
  computed: {
    companyName() {
      return this.propertyTrans(this.company?.name);
    }
  }
}
</script>