<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale.replace('_', '-').toLowerCase()
      }
    };
  },
}
</script>