<template>
  <MessageCard type="error" :message="$t('errors.unsupported_provider')" />
</template>

<script>
import MessageCard from "@/components/MessageCard";
export default {
name: "UnsupportedProvider",
  components: {MessageCard}
}
</script>