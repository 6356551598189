<template>
  <div class="card shadow border-0">
    <div class="card-body">
      <div class="text-center mb-3">
        <div class="loading-spinner spinner-border text-secondary" role="status">
          <span class="sr-only">{{$t('form_based_provider.loading')}}</span>
        </div>
      </div>
      <div class="lead text-center mb-3">
        {{$t('form_based_provider.redirect_message')}}
      </div>

      <hr />
      <div class="text-muted text-center">
        {{$t('form_based_provider.do_not_close')}}
      </div>

      <form method="post" :action="params.target_url" ref="payForm">
        <input v-for="(v, k) in params.params" :key="k" type="hidden" :name="k" :value="v">
        <noscript>
          <button type="submit" id="checkout-btn" class="btn btn-outline-dark">{{$t('form_based_provider.go_to_checkout')}}</button>
        </noscript>
      </form>
    </div>
  </div>

</template>

<script>
import PayMixin from "@/components/PayMixin";

export default {
  name: "FormBasedProviderWidget",
  mixins: [PayMixin],
  mounted() {
    this.$nextTick(() => {
      this.$refs['payForm'].submit();
    });
  }
}
</script>