<template>
  <div class="card shadow border-0">
    <div class="card-body py-5 text-center">
      <slot name="prepend" />
      <div class="message-icon mb-2" :class="iconClass"></div>
      <slot>
        <div class="lead">{{message}}</div>
      </slot>
      <slot name="append" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageCard",
  props: {
    type: String,
    message: String,
  },
  computed: {
    iconClass() {
      return this.type === 'error' ? 'error-icon' : 'check-icon';
    }
  }
}
</script>

<style scoped>

</style>