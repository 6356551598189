<template>
  <div class="loading-spinner spinner-border text-secondary"
       :class="`loading-spinner--${size}`"
       role="status">
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
  props: {
    size: {
      type: String,
      default: 'md'
    }
  }
}
</script>