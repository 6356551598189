<template>
  <PageLayout :merchant="data != null ? data.merchant : null"
              :company="data != null ? data.company : null">
    <!-- Network Error -->
    <MessageCard v-if="error" type="error" :message="error" />

    <!-- Full-width Loading -->
    <div class="card shadow border-0" v-else-if="isLoading || !data?.invoice">
      <div class="card-body text-center">
        <LoadingSpinner  />
      </div>
    </div>

    <!-- Data Loaded -->
    <PageMain v-else
              :company="data ? data.company : null"
              :merchant="data ? data.merchant : null"
              :invoice="data ? data.invoice : null">
      <PaymentMethodChooser :methods="data?.invoice?.payment_link_options?.payment_methods" />
    </PageMain>
  </PageLayout>
</template>

<script>
import PageLayout from "@/components/PageLayout";
import PageMain from "@/components/PageMain";
import LoadingSpinner from "@/components/LoadingSpinner";
import MessageCard from "@/components/MessageCard";
import PaymentMethodChooser from "@/components/paylink/PaymentMethodChooser";
export default {
  name: "PayInvoicePage",
  components: {PaymentMethodChooser, MessageCard, LoadingSpinner, PageMain, PageLayout},
  data() {
    return {
      data: null,
      error: null,
    }
  },
  computed: {
    invoiceId() {
      return this.$route.params.invoiceId;
    },
    token() {
      return this.$route.params.token;
    },
    isLoading() {
      return !this.error && (this.data == null || this.data.invoice == null);
    }
  },
  methods: {
    async loadInvoice() {
      try {
        this.data = (await this.axios.get(`pay/${this.invoiceId}/${this.token}`)).data;
        this.$i18n.locale = this.data.invoice.preferred_locale;
      } catch (e) {
        this.error = e.response?.data?.message.toString() || e.toString();
      }
    },
    async reload() {
      await this.loadInvoice();
    },
  },
  mounted() {
    this.loadInvoice();
  }
}
</script>
