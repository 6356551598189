<template>
  <form class="payment-method-chooser" @submit.prevent="onSubmit">
    <div class="card-body">
      <h4>{{$t('choose_payment_method_title')}}</h4>
      <p>{{$t('choose_payment_method_description')}}</p>

      <div class="row">
        <div class="col-6 col-md-6 col-lg-4 my-2" v-for="method in methods" :key="method">
          <input type="radio" v-model="selected" :value="method" :id="`payment_method_${method}`"
                 class="payment-method-radio sr-only" />
          <label class="payment-method-option" :for="`payment_method_${method}`">
            <img :src="`/assets/${method}.svg`" alt="" class="payment-method-icon" />
            <span class="payment-method-name">
              {{$t('payment_method_label.'+method)}}
            </span>
          </label>
        </div>
      </div>

      <div class="alert alert-danger" v-if="error">
        {{error}}
      </div>

      <div class="payment-method-action">
        <LoadingSpinner size="xs" v-if="isSubmitting" />
        <button type="submit" :disabled="!selected" class="btn btn-success px-4" v-else>
          {{$t('choose_payment_method_submit')}} &rarr;
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
export default {
  name: "PaymentMethodChooser",
  components: {LoadingSpinner},
  props: {
    methods: Array,
  },
  data() {
    return {
      selected: null,
      isSubmitting: false,
      error: null,
    }
  },
  methods: {
    async onSubmit() {
      const paymentMethod = this.selected;
      this.isSubmitting = true;
      try {
        // Submit request to API server
        const { invoiceId, token } = this.$route.params;
        const res = await this.axios.post(`pay/${invoiceId}/${token}`, {
          payment_method: paymentMethod,
        });

        window.location = res.data.redirect;
      } catch (e) {
        this.isSubmitting = false;
        this.error = e?.response?.data?.message || e.toString();
      }
    }
  }
}
</script>
