<template>
  <div class="card shadow border-0">
    <div class="card-body">
      <div class="text-center">
        <qrcode-vue v-if="params.qrcode" :value="params.qrcode" :size="200" level="H"></qrcode-vue>
        <iframe v-else-if="params.pay_url" :src="params.pay_url" class="qfpay-iframe" ref="iframe"></iframe>
      </div>

      <h5>{{$t('qfpay.instructions')}}</h5>

      <!-- Instruction of AlipayHK -->
      <ol v-if="transaction.payment_method == 'alipay_hk'">
        <i18n path="qfpay.alipay_steps.open_app" tag="li">
          <strong>{{$t('qfpay.alipay_hk')}}</strong>
        </i18n>
        <i18n path="qfpay.alipay_steps.click_scan_button" tag="li">
          <strong>{{$t('qfpay.alipay_hk_scan')}}</strong>
        </i18n>
        <li>{{$t('qfpay.alipay_steps.scan_with_camera')}}</li>
        <i18n path="qfpay.alipay_steps.complete_process" tag="li">
          <strong>{{$t('qfpay.alipay_hk')}}</strong>
        </i18n>
      </ol>

      <!-- Instruction of AlipayCN -->
      <ol v-else-if="transaction.payment_method == 'alipay_cn'">
        <i18n path="qfpay.alipay_steps.open_app" tag="li">
          <strong>{{$t('qfpay.alipay_cn')}}</strong>
        </i18n>
        <i18n path="qfpay.alipay_steps.click_scan_button" tag="li">
          <strong>{{$t('qfpay.alipay_cn_scan')}}</strong>
        </i18n>
        <li>{{$t('qfpay.alipay_steps.scan_with_camera')}}</li>
        <i18n path="qfpay.alipay_steps.complete_process" tag="li">
          <strong>{{$t('qfpay.alipay_cn')}}</strong>
        </i18n>
      </ol>

      <!-- Instruction of WeChat Pay -->
      <ol v-else-if="transaction.payment_method == 'wechatpay_hk' || transaction.payment_method == 'wechatpay_cn'">
        <i18n path="qfpay.wechat_steps.open_app" tag="li">
          <strong>{{$t('qfpay.wechat')}}</strong>
        </i18n>
        <i18n path="qfpay.wechat_steps.click_scan_button" tag="li">
          <strong>{{$t('qfpay.wechat_scan')}}</strong>
        </i18n>
        <li>{{$t('qfpay.wechat_steps.scan_with_camera')}}</li>
        <i18n path="qfpay.alipay_steps.complete_process" tag="li">
          <strong>{{$t('qfpay.wechat')}}</strong>
        </i18n>
      </ol>

      <h5>{{$t('qfpay.already_done')}}</h5>
      <router-link :to="checkLink" class="btn btn-outline-secondary">
        {{$t('qfpay.click_to_verify')}}
      </router-link>
    </div>
  </div>
</template>

<script>
import PayMixin, {LinkMixin} from "@/components/PayMixin";
import QrcodeVue from 'qrcode.vue'

export default {
  name: "QfpayWidget",
  mixins: [PayMixin, LinkMixin],
  components: {QrcodeVue},
  data() {
    return {
      isRedirect: false,
    }
  },
  mounted() {
    if (['wechatpay_hk', 'wechatpay_cn'].includes(this.transaction.payment_method) && this.isMobile) {
      window.location = this.params.pay_url;
    }
  },
  computed: {
    isMobile() {
      return /(android|iphone)/i.test(window.navigator.userAgent);
    }
  }
}
</script>

<style>
.qfpay-iframe {
  width: 210px;
  height: 210px;
  border: none;
}
</style>