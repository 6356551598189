<template>
  <MessageCard type="error" :message="$t('payment_checking_over_attempts')"
               v-if="transaction.status === 'pending' && attempts > 15">
    <template #append>
      <router-link :to="payLink" class="btn btn-outline-secondary mt-3">
        {{$t('retry_payment')}}
      </router-link>
    </template>
  </MessageCard>
  <div class="card shadow border-0" v-else-if="transaction.status === 'pending'">
    <div class="card-body text-center">
      <LoadingSpinner />
      <div class="lead mt-3">{{$t('payment_checking')}}</div>
    </div>
  </div>
  <MessageCard type="success" :message="$t('transaction_completed')"
               v-else-if="transaction.status === 'complete'">
    <template #append>
      <a :href="transaction.return_url" v-if="transaction.return_url"
         class="btn btn-outline-success mt-3">
        {{$t('go_to_platform')}}
      </a>
    </template>
  </MessageCard>
  <MessageCard type="error" :message="$t('errors.not_unpaid')" v-else></MessageCard>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import MessageCard from "@/components/MessageCard";
import PayMixin, {LinkMixin} from "@/components/PayMixin";
export default {
  name: "PaymentCheckPage",
  components: {MessageCard, LoadingSpinner},
  mixins: [PayMixin, LinkMixin],
  data() {
    return {
      attempts: 0,
    }
  },
  methods: {
    loaded() {
      this.attempts++;

      if (this.attempts <= 15 && this.transaction.status === 'pending') {
        setTimeout(async () => {
          this.$emit('reload');
        }, 3000);
      }
    },
  },
  watch: {
    transaction: {
      immediate: true,
      handler() {
        console.log('transaction updated');
        this.loaded();
      }
    }
  }
}
</script>