import Vue from 'vue'
import router from "./routes"

import VueMeta from 'vue-meta'

import axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App.vue'

import "./assets/app.scss";
import i18n from './i18n'

Vue.config.productionTip = false

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.use(VueAxios, axios)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
