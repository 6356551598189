<template>
  <PayWidget :company="company"
             :merchant="merchant"
             :invoice="invoice"
             :transaction="transaction"
             :params="params"
             @reload="$emit('reload')" />
</template>

<script>
import PayWidget from "@/components/PayWidget";
import PayMixin from "@/components/PayMixin";
export default {
  name: "PayPage",
  components: {PayWidget},
  mixins: [PayMixin],
  mounted() {
    if (this.transaction.status === 'complete' || this.transaction.status === 'authorized') {
      this.$router.push({name: 'payment_check', params: this.$route.params});
    } else if (this.transaction.status === 'failed' || this.transaction.status === 'declined') {
      this.$router.push({name: 'payment_fail', params: this.$route.params});
    }
  }
}
</script>