<template>
  <div class="container">
    <!-- Header -->
    <PageHeader :merchant="merchant" />

    <!-- Main -->
    <slot />

    <!-- Footer -->
    <PageFooter :company="company" />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";

export default {
  name: "PageLayout",
  components: {
    PageFooter,
    PageHeader,
  },
  props: {
    merchant: Object,
    company: Object,
  },
  metaInfo() {
    return {
      title: this.propertyTrans(this.merchant?.name) || 'ExpressLink',
      titleTemplate: this.$t('payment_service') + ' | %s',
    };
  },
}
</script>