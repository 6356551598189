<template>
  <div class="card shadow border-0">
    <div class="card-body">
      <template v-if="invoice != null || transaction != null">
        <div class="row">
          <div class="col-12 col-lg">
            <div class="font-weight-bold">{{$t('total_amount')}}</div>
          </div>
          <div class="col-12 col-lg-auto">
            {{ transaction ? transaction.currency : invoice.currency }}
            <span class="display-4 align-middle">
              {{ transaction ? transaction.amount_total : invoice.amount_total }}
            </span>
          </div>
        </div>
        <hr />

        <dl>
          <dt>{{$t('reference_no')}}:</dt>
          <dd class="lead">{{ invoice != null ? invoice.merchant_ref : '--' }}</dd>

          <dt>{{$t('merchant_name')}}:</dt>
          <dd class="lead">{{ company != null ? propertyTrans(company.name) : '--' }}</dd>

<!--          <dt>{{$t('platform')}}:</dt>-->
<!--          <dd class="lead">{{ merchant != null ? propertyTrans(merchant.name) : '&#45;&#45;' }}</dd>-->
        </dl>
      </template>
      <div class="text-center" v-else>
        <LoadingSpinner  />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import PayMixin from "@/components/PayMixin";
export default {
  name: "TransactionSummary",
  components: {LoadingSpinner},
  mixins: [PayMixin]
}
</script>
