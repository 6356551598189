<template>
  <div class="pt-4 pb-3">
    <div class="d-flex justify-content-between flex-column flex-md-row">
      <!-- Merchant Branding -->
      <h1 v-if="merchant != null">
        <img v-if="merchant.logo_url != null"
             :src="merchant.logo_url" :alt="merchant.name" style="height: 36px" />
        <span v-else>{{merchant.name}}</span>
      </h1>
      <div v-else><!-- Placeholder --></div>

      <h2>{{$t('payment_service')}}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    merchant: Object,
  }
}
</script>

<style scoped>

</style>