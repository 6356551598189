<template>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-7 mb-3">
      <slot />
    </div>
    <div class="col-12 col-md-6 col-lg-5 mb-3">
      <TransactionSummary :merchant="merchant"
                          :company="company"
                          :invoice="invoice"
                          :transaction="transaction" />
    </div>
  </div>
</template>

<script>
import TransactionSummary from "@/components/TransactionSummary";
import PayMixin from "@/components/PayMixin";
export default {
name: "PageMain",
  components: {TransactionSummary},
  mixins: [PayMixin]
}
</script>

<style scoped>

</style>