<template>
  <canvas ref="canvas" id="payCodeCanvas" :width="size" :height="size"></canvas>
</template>

<script>
import { create_qrcode } from "./qrcode";
import { drawPayCode } from "./paycode";

export default {
  name: "PayMeQrcode",
  props: {
    url: String,
    logos: Object,
    size: {
      type: Number,
      default: 360
    }
  },
  mounted() {
    this.updateQrcode();
  },
  methods: {
    updateQrcode: function ()  {
      const t = 0;
      const e = 'Q';
      const m = 'Byte';
      const mb = 'UTF-8';
      const text = this.url;

      const qr = create_qrcode(text, t, e, m, mb);
      const size = this.size;
      const consumer = false;

      const canvas = this.$refs['canvas'];
      const ctx = canvas.getContext('2d');
      const logo = new Image();
      logo.src = this.logos.large;

      canvas.width = size;
      canvas.height = size;
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawPayCode(qr, canvas, 7, logo, consumer);
    }
  }
}
</script>