<template>
  <div class="container py-5">
    <MessageCard type="error" message="Page Not Found" />
  </div>
</template>

<script>
import MessageCard from "@/components/MessageCard";
export default {
name: "NotFoundPage",
  components: {MessageCard}
}
</script>

<style scoped>

</style>