<script>
import FormBasedProviderWidget from "./FormBasedProviderWidget";

export default {
  name: "AsiaPayWidget",
  extends: FormBasedProviderWidget,
  mounted() {
    console.log(this.params);
  }
}
</script>