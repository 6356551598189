<template>
  <MessageCard type="error">
    <div class="lead">{{$t('payment_cancelled')}}</div>
    <div class="text-muted">{{message || $t('payment_cancelled_description')}}</div>

    <div class="text-center mt-3">
      <a :href="transaction.return_url" v-if="transaction && transaction.return_url"
         class="btn btn-outline-secondary">
        {{$t('go_back')}}
      </a>
      <router-link :to="payLink" class="btn btn-outline-secondary mr-1" v-else>
        {{$t('retry_payment')}}
      </router-link>
    </div>
  </MessageCard>
</template>

<script>
import MessageCard from "@/components/MessageCard";
import PayMixin, {LinkMixin} from "@/components/PayMixin";

export default {
  name: "PaymentFailPage",
  components: {MessageCard},
  mixins: [PayMixin, LinkMixin],
  computed: {
    message() {
      return this.$route.query.message;
    }
  }
}
</script>