import Vue from "vue";
import VueRouter from "vue-router";
import PaymentPage from "@/pages/PaymentPage";
import NotFoundPage from "@/pages/NotFoundPage";
import PaymentFailPage from "@/pages/PaymentFailPage";
import PaymentCheckPage from "@/pages/PaymentCheckPage";
import PayPage from "@/pages/PayPage";
import PayInvoicePage from "@/pages/Invoice/PayInvoicePage";

Vue.use(VueRouter)

const routes = [
    {
        path: '/pay/:invoiceId/:token',
        component: PayInvoicePage,
    },
    {
        path: '/pay/:invoiceId/:txnId/:token',
        component: PaymentPage,
        children: [
            { path: 'check', component: PaymentCheckPage, name: 'payment_check' },
            { path: 'fail', component: PaymentFailPage, name: 'payment_fail' },
            { path: '', component: PayPage, name: 'pay' },
        ]
    },
    { path: '*', component: NotFoundPage },
]

export default new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
})