<script>
import FormBasedProviderWidget from "./FormBasedProviderWidget";

export default {
    name: "ReconWidget",
    extends: FormBasedProviderWidget,
    mounted() {
        console.log(this.params);
    }
}
</script>