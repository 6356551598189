<template>
  <PageLayout :merchant="data != null ? data.merchant : null"
              :company="data != null ? data.company : null">
    <!-- Network Error -->
    <MessageCard v-if="error" type="error" :message="error" />

    <!-- Full-width Loading -->
    <div class="card shadow border-0" v-else-if="isLoading">
      <div class="card-body text-center">
        <LoadingSpinner  />
      </div>
    </div>

    <!-- Data Loaded -->
    <PageMain v-else
              :company="data ? data.company : null"
              :merchant="data ? data.merchant : null"
              :invoice="data ? data.invoice : null"
              :transaction="data ? data.transaction : null">
      <!-- Error Message -->
      <MessageCard v-if="data.type === 'error'"
                   type="error"
                   :message="data.error_code && $te('errors.'+data.error_code) ? $t('errors.'+data.error_code) : data.message">
        <template #append>
          <a class="btn btn-outline-secondary mt-3" :href="data.transaction.return_url">{{$t('go_back')}}</a>
        </template>
      </MessageCard>

      <!-- Payment Content -->
      <router-view v-bind="data" @reload="reload" v-else />
    </PageMain>
  </PageLayout>
</template>

<script>
import PageLayout from "@/components/PageLayout";
import PageMain from "@/components/PageMain";
import LoadingSpinner from "@/components/LoadingSpinner";
import MessageCard from "@/components/MessageCard";
import {GetTransactionMethodMixin, LinkMixin} from "@/components/PayMixin";
export default {
  name: "PaymentPage",
  components: {MessageCard, LoadingSpinner, PageMain, PageLayout},
  mixins: [GetTransactionMethodMixin, LinkMixin],
  data() {
    return {
      data: null,
      error: null,
    }
  },
  computed: {
    invoiceId() {
      return this.$route.params.invoiceId;
    },
    txnId() {
      return this.$route.params.txnId;
    },
    token() {
      return this.$route.params.token;
    },
    isLoading() {
      return !this.error && (this.data == null || this.data.invoice == null || this.data.transaction == null);
    }
  },
  methods: {
    async getTransactionData() {
      try {
        this.data = await this.getTransaction();
        this.$i18n.locale = this.data.invoice.preferred_locale;

        if (this.$route.name === 'pay' && this.data.transaction.status === 'complete') {
          await this.$router.push(this.checkLink);
        }
      } catch (e) {
        this.error = e.response?.data?.message.toString() || e.toString();
      }
    },
    async reload() {
      await this.getTransactionData();
    }
  },
  mounted() {
    this.getTransactionData();
  }
}
</script>