export const GetTransactionMethodMixin = {
    methods: {
        async getTransaction() {
            const res = await this.axios.get(`pay/${this.invoiceId}/${this.txnId}/${this.token}`);
            return res.data;
        }
    }
}
export const LinkMixin = {
    computed: {
        payLink() {
            const { invoiceId, txnId, token } = this.$route.params;

            return {
                name: 'pay',
                params: {
                    invoiceId, txnId, token
                }
            };
        },
        checkLink() {
            const { invoiceId, txnId, token } = this.$route.params;

            return {
                name: 'payment_check',
                params: {
                    invoiceId, txnId, token
                }
            };
        }
    }
}

export function isMobile() {
    return window.navigator
}

export default {
    props: {
        merchant: Object,
        company: Object,
        invoice: Object,
        transaction: Object,
        params: Object,
    }
}