<template>
  <div class="card shadow border-0">
    <div class="card-body">
      <div class="text-center mb-3">
        <div class="loading-spinner spinner-border text-secondary" role="status">
          <span class="sr-only">{{$t('form_based_provider.loading')}}</span>
        </div>
      </div>
      <div class="lead text-center mb-3">
        {{$t('form_based_provider.redirect_message')}}
      </div>

      <hr />
      <div class="text-muted text-center">
        {{$t('form_based_provider.do_not_close')}}
      </div>
    </div>
  </div>
</template>

<script>
import PayMixin, {LinkMixin} from "@/components/PayMixin";

export default {
  name: "AtomeWidget",
  mixins: [PayMixin, LinkMixin],
  mounted() {
    window.location = this.params.redirect_url;
  },
}
</script>