<template>
  <div class="card shadow border-0">
    <div class="card-body">
      <img src="@/assets/payme_with_hsbc.svg" alt="PayMe from HSBC"
           style="width: 298px; height: 75px" />
    </div>
    <PayCodeExpired v-if="isExpired" :return-url="transaction.return_url"></PayCodeExpired>
    <div class="card-body" v-else-if="isMobile">
      <div class="text-muted text-center mb-3">
        {{$t('payme.mobile_redirect_description')}}
      </div>
      <div class="text-center">
        <a :href="params.webLink" target="_blank">
          <img src="@/assets/payme_btn.svg" :alt="$t('payme.pay_with_payme')" />
        </a>
      </div>
    </div>
    <div class="card-body" v-else>
      <div class="text-center">
        <h5>{{$t('payme.paycode_scan')}}</h5>
        <PayMeQrcode :url="params.webLink" :logos="params.businessLogos" />

        <p class="text-muted">{{$t('payme.paycode_stay')}}</p>
      </div>

      <hr />
      <h5>{{$t('payme.paycode_how')}}</h5>
      <ol>
        <li>{{$t('payme.paycode_step_1')}}</li>
        <li>{{$t('payme.paycode_step_2')}}</li>
        <li>{{$t('payme.paycode_step_3')}}</li>
      </ol>
    </div>
  </div>
</template>

<script>
import PayMixin from "@/components/PayMixin";
import PayMeQrcode from "@/components/providers/payme/PayMeQrcode";
import addSeconds from "date-fns/addSeconds";
import parseISO from "date-fns/parseISO";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import PayCodeExpired from "@/components/providers/payme/PayCodeExpired";

export default {
  name: "PayMeWidget",
  components: {PayCodeExpired, PayMeQrcode},
  mixins: [PayMixin],
  data() {
    return {
      isExpired: false
    }
  },
  mounted() {
    this.createTimers();
  },
  methods: {
    createTimers() {
      // Create Expiry Message Timer
      const now = new Date();
      const expiry = addSeconds(parseISO(this.params.createdTime), this.params.effectiveDuration);
      const timeLeft = differenceInMilliseconds(expiry, now);
      if (timeLeft > 0) {
        setTimeout(() => {
          this.showExpiredMessage();
        }, timeLeft);
      } else {
        this.showExpiredMessage();
      }

      // Create Check Status Timer after 10s
      setTimeout(() => {
        this.checkStatus();
      }, 10000);
    },
    showExpiredMessage() {
      this.isExpired = true;
    },
    checkStatus() {
      this.$emit('reload');
      setTimeout(() => {
        this.checkStatus();
      }, this.isExpired ? 8000 : 3000);
    }
  },
  computed: {
    isMobile() {
      return /(android|iphone|flutter)/i.test(window.navigator.userAgent);
    }
  }
}
</script>