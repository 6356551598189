<template>
  <div class="card-body text-center">
    <div class="message-icon error-icon mb-2"></div>

    <h5 class="lead">{{$t('payme.paycode_expired_title')}}</h5>
    <p class="text-muted">{{$t('payme.paycode_expired_message')}}</p>

    <a href="javascript:window.history.back()" class="btn btn-outline-dark">{{$t('go_back')}}</a>
  </div>
</template>

<script>
export default {
  name: "PayCodeExpired",
  props: {
    returnUrl: String
  }
}
</script>